<template>
  <div class="outer-wrapper">
    <Icon
      type="md-arrow-dropright"
      class="menu-ico"
      v-show="!swValue"
      @click="() => (swValue = true)"
      :size="50"
    />
    <div class="mask-box" v-show="swValue">
      <div class="inner-wrapper">
          <Menu theme="dark" :open-names="['0','1']">
      <Submenu :name="ele.id" v-for="ele in menuData.filter(val=>val.ifShow)" :key="ele.id">
        <template slot="title">
          <Icon :type="ele.icon" />
          {{ele.text}}
        </template>
        <MenuItem :name="i.id" :to="{name:i.to}" v-for="i in ele.children.filter(val=>val.ifShow)" :key="i.id">{{i.text}}</MenuItem>
      </Submenu>
    </Menu>
        <!-- <Menu class="menu-i" theme="dark" :open-names="openNames" accordion>
          <Submenu name="1">
            <template slot="title">
              <Icon type="ios-paper" />
              数据管理
            </template>
            <MenuItem
              v-for="item in loginAuth"
              :name="item.value"
              :to="'/entry/' + item.value"
              :key="item.value"
              >{{ item.text }}</MenuItem
            >
            <MenuItem name="risk"><router-link to="/entry/riskEntry">风险点</router-link></MenuItem>
                            <MenuItem name="accident"><router-link to="/entry/riskEntry">故障</router-link></MenuItem>
                            <MenuItem name="line"><router-link to="/entry/riskEntry">线路概况</router-link></MenuItem>
                            <MenuItem name="evaluate"><router-link to="/entry/reportEntry">安评项目</router-link></MenuItem>
                            <MenuItem name="purchase"><router-link to="/entry/riskEntry">采购</router-link></MenuItem>
                            <MenuItem name="passangerflow"><router-link to="/entry/riskEntry">客流</router-link></MenuItem>
                            <MenuItem name="equipment"><router-link to="/entry/riskEntry">设备</router-link></MenuItem>
                            <MenuItem name="correct"><router-link to="/entry/riskEntry">整改进度</router-link></MenuItem>-->
            <!-- <MenuItem name="客流数据" to="/entry/passengerEntry" key="padd">客流数据</MenuItem>
            <MenuItem name="资料管理" to="/entry/rule_m" key="rule_m"
              >资料管理</MenuItem
            >
          </Submenu>
          <Submenu name="2">
            <template slot="title">
              <Icon type="ios-archive-outline" />
              数据管理
            </template>
            <MenuItem name="backUp" to="/entry/backup">备份还原</MenuItem>
          </Submenu>
          <Submenu name="3">
            <template slot="title">
              <Icon type="ios-cog" />
              个人设置
            </template>
            <MenuItem name="changePwd" to="/entry/aps">修改密码</MenuItem>
          </Submenu>
        </Menu> -->
      </div>
      <div class="rest" @click.stop="()=>swValue=false"></div>
    </div>
  </div>
</template>
<script>
let authority = [];
let authData = JSON.parse(localStorage.getItem("authData"));
if (authData) {
  authority = authData.barRights;
}
export default {
  name: "menuBar",
  data() {
    return {
      swValue: false,
      defaultMenuData: [
          {
          id: "0",
          text: "用户权限",
          ifShow:true,
          icon: "ios-people",
          children: [
            { text: "用户管理", id: "0-0", to: "userManage",ifShow:true, },
            { text: "权限管理", id: "0-1", to: "roleManage",ifShow:true, },
          ]
        },
        {
          id: "1",
          text: "线网基本信息",
          ifShow:true,
          icon: "ios-subway",
          children: [
            { text: "线路", id: "1-1", to: "lineManage",ifShow:true, },
            { text: "网络", id: "1-2", to: "netManage",ifShow:false, },
          ]
        },
        {
          id: "2",
          text: "运营基础指标",
          ifShow:true,
          icon: "ios-podium",
          children: [
            { text: "运营数据", id: "2-1", to: "operationManage",ifShow:true, },
          ]
        },
        {
          id: "3",
          text: "网络化运营",
          ifShow:true,
          icon: "ios-crop",
          children: [
            { text: "应急值守点", id: "3-0", to: "emergencyManage",ifShow:true, },
            { text: "区域应急中心", id: "3-1", to: "areaEmergencyManage",ifShow:true, },
            { text: "应急物资", id: "3-2", to: "emergencyGoodsManage",ifShow:true, },
            { text: "应急预案", id: "3-3", to: "emergencySchemeManage",ifShow:true, },
            { text: "应急记录", id: "3-4", to: "emergencyRecordManage",ifShow:true, },
            { text: "应急计划", id: "3-5", to: "emergencyPlanManage",ifShow:true, },
            { text: "客流换乘", id: "3-6", to: "transferManage",ifShow:true, },
            // { text: "评价结论", id: "3-3", to: "netOpManage",ifShow:true, },
          ]
        },
        {
          id: "4",
          text: "评估期间数据",
          ifShow:true,
          icon: "ios-grid",
          children: [
            { text: "风险分级管控", id: "4-1", to: "periodRiskManage",ifShow:true, },
            { text: "隐患排查治理", id: "4-2", to: "latentDangerManage",ifShow:true, },
            { text: "险性事件分析", id: "4-3", to: "accidentManage",ifShow:true, },
          ]
        },
        {
          id: "5",
          text: "安全评估结论",
          ifShow:true,
          icon: "ios-map",
          children: [
            { text: "风险点数据库", id: "5-1", to: "resultRiskManage",ifShow:true, },
            { text: "安评报告上传", id: "5-2", to: "reportManage",ifShow:true, },
            { text: "风险整改进度", id: "5-3", to: "correctManage",ifShow:true, },
          ]
        },
        {
          id: "6",
          text: "其他",
          ifShow:true,
          icon: "ios-pricetags",
          children: [
            { text: "专业专家信息", id: "6-1", to: "expertManage",ifShow:true, },
            { text: "评价规范管理", id: "6-2", to: "ruleManage",ifShow:true, },
          ]
        },
        {
          id: "7",
          text: "数据管理",
          ifShow:true,
          icon: "ios-undo",
          children: [
            { text: "备份还原", id: "7-1", to: "backupManage",ifShow:true, },
          ]
        },
        
      ]
    };
  },
  created() {
  },
  methods: {},
  computed:{
      menuData:function(){
        let newData = JSON.parse(JSON.stringify(this.defaultMenuData));
        // return newData;//测试
          if(authority.indexOf('super_all')!==-1){
              return newData;
          }else if(authority.length===0){
              return [];
          }else{
          for(let i=0;i<this.defaultMenuData.length;i++){
              for(let j=0;j<this.defaultMenuData[i].children.length;j++){
                  if(authority.indexOf(this.defaultMenuData[i].children[j].to)===-1){
                      newData[i].children[j].ifShow = false;
                  }else{
                    newData[i].children[j].ifShow = true;
                  }
              }
              if(newData[i].children.every(ele=>{return !ele.ifShow;})){
                  newData[i].ifShow = false;
              }else{
                newData[i].ifShow = true;
              }
          }
          return newData;
          }
      },
  }
};
</script>
<style scoped>
.inner-wrapper {
  position: absolute;
  left: 0;
  height: 100%;
  background-color: #515a6e;
  z-index:128;
}

.mask-box {
  position: absolute;
  height: 100%;
  width: 100%;
}

.rest{
    position: absolute;
    height:100%;
    width:100%;
    z-index:127;
}

.menu-ico {
  position: absolute;
  left: 0;
  top: 40%;
}
</style>